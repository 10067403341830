import React from 'react'
import Markdown from 'markdown-to-jsx'
import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router-dom'

import Loader from 'components/loaders/Loader'
import useFetchPage from 'hooks/useFetchPage'
import { breakpointMax } from 'styles/mixins'
import { isSurgicalNotesProduct } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ typography, colors, blockColors }) => ({
  container: {
    margin: [25, 'auto', 25, 'auto'],
    maxWidth: 1200,
    width: '70%',

    [breakpointMax('lg')]: {
      width: '90%'
    },

    [breakpointMax('xs')]: {
      width: '85%'
    },

    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.medium,
    fontWeight: typography.fontWeightVariants.regular,
    letterSpacing: typography.fontLetterSpacingVariants.normal,
    lineHeight: typography.lineHeightVariants.normal,

    '& a': {
      fontSize: typography.fontSizeVariants.small,
      fontWeight: typography.fontWeightVariants.medium,
      color: blockColors.freeSpeechBlue
    },

    '& p': {
      margin: [0, 0, 20]
    },

    '& ul': {
      padding: 0
    },

    '& li > p': {
      margin: 0
    },

    '& strong': {
      color: colors.tertiary,
      fontWeight: typography.fontWeightVariants.semibold
    },

    '& li::marker': {
      color: colors.tertiary,
      fontSize: 20
    }
  }
}))

const markdownOptions = {
  overrides: {
    a: props => (
      <a {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    ),
    ol: props => <ul {...props}>{props.children}</ul>
  }
}

function Eula() {
  const { lang } = useParams()
  const classes = useStyles()

  const [page, loading] = useFetchPage(
    isSurgicalNotesProduct()
      ? '/webviews/surgicalnotes/eula'
      : '/webviews/eula',
    lang
  )
  return loading ? (
    <Loader />
  ) : (
    <div className={classes.container}>
      <Markdown options={markdownOptions}>{page.blocks[0].content}</Markdown>
    </div>
  )
}

export default Eula
