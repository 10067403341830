import { ApolloLink } from '@apollo/client'
import { camelCase, upperFirst } from 'lodash'

import cache from 'client/cache'
import { GET_AUTH } from 'client/state/auth'
import { getCurrentProduct } from 'lib/CheckProduct'

const authLink = new ApolloLink((operation, forward) => {
  const authObject = cache.readQuery({ query: GET_AUTH })

  const { auth: { currentToken } = {} } = authObject || {}

  const context = operation.getContext()
  context.headers = context.headers || {}
  context.headers['X-Product'] = upperFirst(camelCase(getCurrentProduct()))

  if (currentToken) {
    context.headers['X-Access-Token'] = currentToken
  }
  operation.setContext(context)

  return forward(operation)
})

export default authLink
