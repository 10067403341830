import dx from 'lib/dashx'
import { last } from 'lodash'
import { useEffect, useState } from 'react'

import usePersistedState from 'hooks/usePersistedState'
import { DB_USER_LANGUAGE_KEY } from 'models/Account'

const languageMap = {
  'Français (French)': 'fr_FR',
  English: 'en_US'
}

const localeMap = {
  fr: 'fr_FR',
  en: 'en_US'
}

function useFetchPage(route, locale) {
  const [page, setPage] = useState('')
  const [loading, setLoading] = useState(true)
  const [userLanguage] = usePersistedState(DB_USER_LANGUAGE_KEY, 'English')
  const localeScope = localeMap[locale] || localeMap.en
  const language = localeScope || languageMap[userLanguage] || 'en_US'

  useEffect(() => {
    const filter = route ? { route: { eq: route } } : {}

    dx.searchRecords('page')
      .language(language)
      .preview(import.meta.env.VITE_ENV !== 'production')
      .filter(filter)
      .all()
      .then(data => {
        if (data) {
          setPage(last(data))
          setLoading(false)
        }
      })
  }, [route])

  return [page, loading]
}

export default useFetchPage
