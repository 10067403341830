const alerts = {
  en: {
    success_title: 'Success!',
    failure_title: 'Failed!',

    // generic
    delayed_import: 'File contents will be imported shortly.',
    bad_request: "We've received a bad request, please try again.",
    no_response: 'Please try again after some time.',
    unauthorized: 'You seem to have logged out.',
    forbidden: 'You are not allowed to do that.',
    not_found: 'That resource does not exist.',
    not_invited:
      'You have not yet been invited to this organization. Please check with your administrator.',
    inactive:
      'You do not have access to this organization. Please check with your administrator.',
    no_account: 'No account exists with that email.',
    internal_server_error:
      'Something went wrong. Our engineers are looking into it.',
    other_error: "We've been notified. Please try after some time.",
    account_locked: 'Max Retries Reached. Try logging in after 10 minutes.',
    account_not_found: 'External login was unsuccessful. Please try again.',
    account_unverified:
      "We couldn't authenticate your account against your external login provider. Please contact your administrator.",
    print_document_error:
      "You don't have a document to print for the selected document types.",
    // specific
    registration_complete:
      'Welcome! We have sent you an email with helpful information to get you up and running.',
    payment_already_setup: "You've already setup your payment information.",
    forgot_password:
      'You will receive an email with instructions on how to reset your password in a few minutes.',
    reset_password: "You've successfully reset your password.",
    integration_hub_ignore_success: 'Record successfully ignored.',
    integration_hub_archive_success: 'Record successfully archived.',
    integration_hub_import_success: 'Record successfully imported.',
    integration_hub_recommendation_success: 'Record successfully recommended.',
    integration_hub_cancel_success: 'Request successfully cancelled.',
    integration_hub_status_change_success: 'Status successfully updated',
    request_license: 'License Request has been sent to Account manager',
    enquire_solution: 'Solution enquiry request sent to the account manager',
    create_license: 'License successfully added.',
    remove_license: 'License successfully removed.'
  },
  fr: {
    success_title: 'Succès!',
    failure_title: 'Échoué!',

    // generic
    delayed_import: 'Le contenu du fichier sera importé sous peu.',
    bad_request: 'Nous avons reçu une mauvaise demande, veuillez réessayer.',
    no_response: 'Veuillez réessayer plus tard.',
    unauthorized: 'Vous semblez être déconnecté.',
    forbidden: 'Vous n’êtes pas autorisé à faire cela.',
    not_found: 'Cette ressource n’existe pas.',
    not_invited:
      "Vous n'avez pas encore été invité à cette organisation. Veuillez vérifier avec votre administrateur.",
    inactive:
      "Vous n'avez pas accès à cette organisation. Veuillez vérifier avec votre administrateur.",
    no_account: "Aucun compte n'existe avec cet e-mail.",
    internal_server_error:
      "Quelque chose s'est mal passé. Nos ingénieurs y travaillent.",
    other_error: 'Nous avons été informés. Veuillez réessayer plus tard.',
    account_locked:
      'Nombre maximum de tentatives atteint. Réessayez dans 10 minutes.',
    account_not_found: 'La connexion externe a échoué. Veuillez réessayer.',
    account_unverified:
      "Nous n'avons pas pu authentifier votre compte auprès de votre fournisseur de connexion externe. Veuillez contacter votre administrateur.",
    print_document_error:
      "Vous n'avez pas de document à imprimer pour les types de documents sélectionnés.",
    // specific
    registration_complete:
      'Bienvenue! Nous vous avons envoyé un e-mail avec des informations utiles pour commencer.',
    payment_already_setup:
      'Vous avez déjà configuré vos informations de paiement.',
    forgot_password:
      'Vous recevrez un e-mail avec des instructions pour réinitialiser votre mot de passe dans quelques minutes.',
    reset_password: 'Votre mot de passe a été réinitialisé avec succès.',
    integration_hub_ignore_success: 'Enregistrement ignoré avec succès.',
    integration_hub_archive_success: 'Enregistrement archivé avec succès.',
    integration_hub_import_success: 'Enregistrement importé avec succès.',
    integration_hub_recommendation_success:
      'Enregistrement recommandé avec succès.',
    integration_hub_cancel_success: 'Demande annulée avec succès.',
    integration_hub_status_change_success: 'Statut mis à jour avec succès.',
    request_license: 'Demande de licence envoyée au gestionnaire de compte.',
    enquire_solution: 'Demande de solution envoyée au gestionnaire de compte.',
    create_license: 'Licence ajoutée avec succès.',
    remove_license: 'Licence supprimée avec succès.'
  }
}

export const getAlertMessages = () => {
  const userLanguage = localStorage.getItem('DB_USER_LANGUAGE') || 'English'
  return alerts[userLanguage.includes('French') ? 'fr' : 'en']
}

export default getAlertMessages()
