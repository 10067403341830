import capitalize from 'lodash/capitalize'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { kebabCase } from 'lodash'
import { NavLink } from 'react-router-dom'

import accessibleOnClick from 'lib/accessibleOnClick'
import FontIcon from 'components/base/FontIcon'
import Logo from 'components/base/Logo'
import pluralize from 'lib/pluralize'
import Text from 'components/typography/Text'
import Tooltip from 'components/popovers/Tooltip'
import useAppContext from 'hooks/useAppContext'
import useDefaultTab from 'hooks/useDefaultTab'
import useFeature, { featureTypes } from 'hooks/useFeature'
import usePermissions, { permissions } from 'hooks/usePermissions'
import usePersistedState from 'hooks/usePersistedState'
import useSetting, { settingTypes } from 'hooks/useSetting'
import { accountType } from 'models/Account'
import isVetBuddyProduct, { isSurgicalNotesProduct } from 'lib/CheckProduct'
import { makeRoundedCorners, transitionFluid } from 'styles/mixins'
import { currentLanguage } from 'locales/i18n'

const menuItemNames = {
  en: {
    notes: 'Notes',
    voiceCommands: 'Voice Commands'
  },
  fr: {
    notes: 'Remarques',
    voiceCommands: 'Commandes vocales'
  }
}

const useStyles = createUseStyles(({ colors, typography, zIndexes }) => {
  const animationConstants = {
    icon: 20,
    iconBackground: -12
  }

  return {
    aside: {
      ...(isSurgicalNotesProduct()
        ? {
          backgroundImage: `linear-gradient(${colors.secondary}, ${colors.primaryVivid})`
        }
        : { backgroundColor: colors.primary }),
      borderTopRightRadius: 40,
      gridArea: 'aside',
      height: '100vh',
      minHeight: '100vh',
      paddingTop: 30,
      position: 'sticky',
      top: 0,
      width: props => {
        if (props.isCollapsed) {
          return 75
        }
        if (props.isFrench) {
          return 245
        }
        return 225
      },
      transition: 'width 0.5s ease',
      zIndex: zIndexes.popup
    },
    logoSection: {
      marginBottom: 30,
      textAlign: 'center',
      height: 80,
      transition: '0.1s ease',
      position: 'relative'
    },
    fullWidthLogo: {
      position: 'absolute',
      marginLeft: () => (isSurgicalNotesProduct() ? -5 : -9),
      opacity: props => (props.isCollapsed ? 0 : 1)
    },
    collapsedLogo: {
      marginLeft: 6,
      opacity: props => (props.isCollapsed ? 1 : 0)
    },
    orgSection: {
      transition: '0.2s ease',
      opacity: props => (props.isCollapsed ? 0 : 1),
      height: 90
    },
    sidebarItemSection: {
      height: '65%',
      overflow: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: `${colors.tertiaryPale} transparent`,
      '&::-webkit-scrollbar': {
        background: 'transparent',
        width: 0,
        borderRadius: 4
      },
      '&:hover': {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: 8
        }
      },
      '&::-webkit-scrollbar-thumb': {
        background: `${colors.tertiaryPale}`,
        width: 8,
        borderRadius: 4
      }
    },
    iconWrapper: {
      height: animationConstants.icon,
      left: animationConstants.icon + 2,
      position: 'absolute',
      top: animationConstants.icon + 2,
      width: animationConstants.icon,
      zIndex: 1
    },
    sidebarItemWrapper: {
      position: 'relative'
    },
    sidebarItemName: {
      marginLeft: 15,
      whiteSpace: 'nowrap',
      opacity: props => (props.isCollapsed ? 0 : 1)
    },
    sidebarItem: {
      ...transitionFluid('color'),
      transition: '0.5s ease',

      borderRadius: [18, 0, 0, 18],
      color: isSurgicalNotesProduct()
        ? colors.secondaryPale
        : colors.secondaryLight,
      display: 'flex',
      fontFamily: typography.fontFamilyVariants.primary,
      fontSize: typography.fontSizeVariants.small,
      padding: [25, 20],

      '& > $sidebarItemName': {
        marginLeft: 40
      },

      '& > $iconWrapper .icon': {
        ...transitionFluid()
      },

      '&:hover': {
        color: colors.light
      },

      '&:hover > $iconWrapper .icon': {
        ...transitionFluid(),
        color: isSurgicalNotesProduct() ? colors.greyLight : colors.tertiary
      }
    },
    sidebarItem_active: {
      background: props => !props.isCollapsed && colors.secondary,
      color: colors.light,
      fontWeight: typography.fontWeightVariants.semibold,
      marginLeft: 35,

      '& > $iconWrapper .icon:after': {
        ...makeRoundedCorners(16),

        animation: '$slide 0.3s ease-in-out',
        background: colors.tertiary,
        content: '""',
        display: 'block',
        height: 50,
        position: 'absolute',
        transform: `translate(${animationConstants.iconBackground}px, ${
          animationConstants.iconBackground * 3
        }px)`,
        width: 50,
        zIndex: -1
      },

      '& > $sidebarItemName': {
        marginLeft: 20
      },

      '& > $iconWrapper .icon': {
        color: isSurgicalNotesProduct() ? colors.light : colors.secondary,
        position: 'absolute'
      },

      '&:hover > $iconWrapper .icon': {
        color: isSurgicalNotesProduct() ? colors.light : colors.secondary,
        position: 'absolute'
      }
    },
    organizationName: {
      marginTop: 10
    },

    '@keyframes slide': {
      '0%': { left: animationConstants.iconBackground },
      '100%': { left: 0 }
    },
    collapseButton: {
      visibility: props => (!props.showCollapseButton ? 'hidden' : 'visible'),
      opacity: props => (!props.showCollapseButton ? 0 : 1),
      transition: props =>
        !props.showCollapseButton ? 'all 0.1s ease-in' : 'all 0.1s ease-out',
      zIndex: 5,
      position: 'sticky',
      top: 90,
      float: 'right',
      margin: [0, -15, 0, 0],
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: colors.primary,
      cursor: 'pointer',
      transform: props => props.isCollapsed && 'rotate(180deg)'
    },
    collapseButtonIcon: {
      marginBottom: 1,
      fontSize: 33
    }
  }
})

function Sidebar() {
  const defaultSetting = useSetting(settingTypes.HAS_SIDEBAR_COLLAPSED) || false
  const [isCollapsed, setIsCollapsed] = usePersistedState(
    'isNavBarCollapsed',
    defaultSetting
  )
  const [showCollapseButton, setShowCollapseButton] = useState(false)

  const classes = useStyles({
    isCollapsed,
    showCollapseButton,
    isFrench: currentLanguage === 'fr'
  })
  const { currentAccount } = useAppContext()

  const canViewAdmin = currentAccount && currentAccount.administrator
  const hasManageIndependentEncountersFeature = useFeature(
    featureTypes.MANAGE_INDEPENDENT_ENCOUNTERS
  )
  const hasViewOnCallRecordsFeature = useFeature(
    featureTypes.VIEW_ON_CALL_RECORDS
  )
  const hasViewOnCallRecordsPermission = usePermissions(
    permissions.VIEW_ON_CALL_RECORDS
  )
  const hasViewOnCallAvailabilitiesFeature = useFeature(
    featureTypes.VIEW_ON_CALL_AVAILABILITIES
  )
  const hasViewOnCallAvailabilitiesPermission = usePermissions(
    permissions.VIEW_ON_CALL_AVAILABILITIES
  )
  const hasViewVoiceCommandsFeature = useFeature(
    featureTypes.VIEW_VOICE_COMMANDS
  )
  const hasViewVoiceCommandsPermission = usePermissions(
    permissions.VIEW_VOICE_COMMANDS
  )
  const hasViewIntegrationHubFeature =
    useFeature(featureTypes.VIEW_INTEGRATION_HUB) &&
    currentAccount?.type === accountType.STAFF
  const hasViewPatientsFeature = useFeature(featureTypes.VIEW_PATIENTS)
  const canViewReports =
    useFeature(featureTypes.VIEW_REPORTS) && !isVetBuddyProduct()
  const hasViewScheduleFeature = useFeature(featureTypes.VIEW_SCHEDULE)

  const defaultTab = useDefaultTab()
  const encounterLabel = useSetting(settingTypes.ENCOUNTER_LABEL)

  const menuItems = [
    {
      name: 'Schedule',
      icon: 'schedule',
      url: '/schedule',
      hidden: !hasViewScheduleFeature
    },
    {
      name: 'Patients',
      icon: 'people',
      url: '/patients',
      hidden: !hasViewPatientsFeature
    },
    {
      name: 'On Call',
      icon: 'on-call',
      url: '/on-call',
      hidden: !(
        (hasViewOnCallRecordsFeature && hasViewOnCallRecordsPermission) ||
        (hasViewOnCallAvailabilitiesFeature &&
          hasViewOnCallAvailabilitiesPermission)
      )
    },
    {
      name: 'Integration Hub',
      icon: 'integration-hub',
      url: '/integration-hub',
      hidden: !hasViewIntegrationHubFeature
    },
    {
      name: menuItemNames[currentLanguage].voiceCommands,
      icon: 'voice-commands',
      url: '/voice-commands',
      hidden: !(hasViewVoiceCommandsFeature && hasViewVoiceCommandsPermission)
    },
    {
      name: menuItemNames[currentLanguage].notes,
      icon: 'notes',
      url: '/notes'
    },
    {
      name: encounterLabel && capitalize(pluralize(encounterLabel)),
      icon: 'encounters',
      url: '/encounters',
      hidden: !hasManageIndependentEncountersFeature
    },
    {
      name: 'Admin',
      icon: 'admin-icon',
      url: '/admin',
      hidden: !canViewAdmin
    },
    {
      name: 'Reports',
      icon: 'reports',
      url: '/reports',
      hidden: !canViewReports
    },
    {
      name: 'Help',
      icon: 'help',
      url: '/help'
    }
  ]

  const handleOnClick = () => {
    setIsCollapsed(prevState => !prevState)
  }

  const renderSidebarItems = menuItems
    .filter(item => !item.hidden)
    .map(item => (
      <div
        data-test={kebabCase(`sidebar-item ${item.name}`)}
        key={item.name}
        className={classes.sidebarItemWrapper}
      >
        <Tooltip
          title={item.name}
          placement="right"
          isParentHovering={!!isCollapsed}
          titleSize="xSmall"
          onTargetClick={handleOnClick}
        >
          <NavLink
            className={classes.sidebarItem}
            to={item.url}
            activeClassName={classes.sidebarItem_active}
          >
            <span className={classes.sidebarItemName}>{item.name}</span>
            <div className={classes.iconWrapper}>
              <FontIcon name={item.icon} size="xLarge" />
            </div>
          </NavLink>
        </Tooltip>
      </div>
    ))

  return (
    <aside
      className={classes.aside}
      onMouseLeave={() => setShowCollapseButton(false)}
      onMouseEnter={() => setShowCollapseButton(true)}
    >
      <div
        className={classes.collapseButton}
        {...accessibleOnClick(() => handleOnClick())}
        aria-label="Show or hide the sidebar menu"
      >
        <FontIcon
          name="sidebar-collapse"
          className={classes.collapseButtonIcon}
          color={isSurgicalNotesProduct() ? 'light' : 'secondary'}
        />
      </div>
      <div className={classes.logoSection}>
        <Logo
          path={defaultTab}
          variant="vertical_full_light"
          className={classes.fullWidthLogo}
        />
        <Logo
          path={defaultTab}
          variant="vertical_icon_light"
          className={classes.collapsedLogo}
        />
      </div>
      <div className={classes.orgSection}>
        {currentAccount && (
          <Text
            align="center"
            className={classes.organizationName}
            color={isSurgicalNotesProduct() ? 'light' : 'grey'}
            data-test="organization-name"
            weight="medium"
          >
            {currentAccount.organization.displayName}
          </Text>
        )}
      </div>
      <div className={classes.sidebarItemSection}>{renderSidebarItems}</div>
    </aside>
  )
}

export default Sidebar
